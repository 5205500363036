import React from 'react'
import { css } from '@emotion/react'
import type { TypographyProps } from '@mui/material/Typography'
import Typography from '@mui/material/Typography'
import Prism from 'prismjs'
import useTheme from '@mui/material/styles/useTheme'

export interface CodeBlockProps extends Partial<TypographyProps> {
  children?: React.ReactNode
  content?: string
  component?: string
}

function CodeBlock(props: React.PropsWithChildren<CodeBlockProps>) {
  const theme = useTheme()
  let { children, content, ...rest } = props
  if (!content && typeof children === 'string') content = children

  return (
    <Typography
      className="language-jsx"
      component="pre"
      {...rest}
      css={css`
        width: 100%;
        margin: auto;
        p {
          font-weight: 300;
        }
        pre {
          margin-bottom: 40px !important;
        }
        h1 {
          font-weight: 300;
        }
        h2 {
          font-weight: 300;
          ${theme.breakpoints.down('xs')} {
            padding: 6px 0;
          }
        }
        h3 {
          font-weight: 400;
        }
        h4 {
          font-weight: 300;
        }
        h5 {
          font-weight: 300;
          ${theme.breakpoints.down('xs')} {
            font-size: 1rem;
          }
        }
        h6 {
          font-weight: 300;
        }
        span,
        pre,
        code {
          line-height: 1.6;
        }
        img {
          max-width: 100%;
          transition: all 0.2s ease-out;
          display: block;
          margin: 25px auto 45px;
          border: 1px solid #e5ebed;
          object-fit: cover;
          :hover {
            transform: scale(1.02) rotate(-1deg);
          }
          ${theme.breakpoints.down('sm')} {
            width: 100%;
            height: 100%;
          }
          ${theme.breakpoints.down('xs')} {
            margin: 16px auto 30px;
          }
        }
        blockquote {
          border-left: 4px solid ${theme.palette.primary.main};
          ${theme.breakpoints.down('xs')} {
            margin: 2px 12px 6px 0;
          }
          p {
            font-style: italic;
            margin-left: 10px;
          }
        }
        table {
          border: 1px solid #285978;
          th {
            background: #285978;
            color: #fff;
            padding: 8px;
          }
          td {
            border: 1px solid #e4eaec;
          }
        }
        ol {
          ${theme.breakpoints.down('xs')} {
            padding: 1px 23px;
          }
        }
        li {
          font-weight: 300;
          ${theme.breakpoints.down('xs')} {
            line-height: 1.2;
          }
        }
      `}
    >
      <code
        className="language-jsx"
        dangerouslySetInnerHTML={{
          __html: Prism.highlight(content as string, Prism.languages.js, 'js'),
        }}
      />
    </Typography>
  )
}

export default CodeBlock
