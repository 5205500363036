import { gql, useMutation } from '@apollo/client'

function useSubscribeNewsletter() {
  const [subscribe, state] = useMutation(
    gql`
      mutation SubscribeToNewsletter(
        $email: String!
        $pageUrl: String
        $type: String!
      ) {
        subscribeToNewsletter(email: $email, pageUrl: $pageUrl, type: $type)
      }
    `,
    {
      update: (cache, data) => {
        const logMsg = '%c[Mutation/subscribeToNewsletter]'
        const logStyle = 'color:#00b406;font-weight:bold;'
        console.log(logMsg, logStyle, { cache, data })
      },
    },
  )

  return {
    ...state,
    subscribe,
  }
}

export default useSubscribeNewsletter
