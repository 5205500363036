import React from 'react'
import useTheme from '@mui/material/styles/useTheme'
import { css } from '@emotion/react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { GatsbyImage } from 'gatsby-plugin-image'
import Typography from '@mui/material/Typography'
import Link from 'components/common/Link'
import useLogo from 'hooks/useLogo'
import { routes, RouteObject } from 'app/config'
import BurgerMenu from './BurgerMenu'

export interface NavbarProps {
  location: any
}

export function Navbar({ location }: NavbarProps) {
  const logo = useLogo()
  const [selected, setSelected] = React.useState(location.pathname)
  const theme = useTheme()

  React.useEffect(() => {
    if (location.pathname !== selected) {
      setSelected(location.pathname)
    }
  }, [location.pathname, selected])

  return (
    <AppBar
      component="nav"
      position="static"
      color="default"
      elevation={0}
      style={{
        background: '#fff',
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <Toolbar
        css={css`
          width: 100%;
          max-width: 1000px;
          margin: auto;
        `}
      >
        <nav
          css={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <Link to="/">
            <GatsbyImage image={logo} alt="jsmanifest logo" />
          </Link>
          <div
            css={css`
              display: flex;
              ${theme.breakpoints.down('xs')} {
                display: none;
              }
            `}
          >
            {routes.map(({ to = '', label }: RouteObject) => (
              <Typography
                component={Link}
                key={to}
                to={to}
                css={css`
                  text-transform: uppercase;
                  margin-right: 25px;
                  opacity: 1;
                  ${theme.breakpoints.down('md')} {
                    font-size: 13px;
                  }
                  ${theme.breakpoints.down('sm')} {
                    font-size: 12px;
                  }
                `}
              >
                {label}
              </Typography>
            ))}
          </div>
          <BurgerMenu routes={routes} selectedRoute={selected} />
        </nav>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
