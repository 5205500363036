import React from 'react'
import { PageProps } from 'gatsby'
import { ToastContainer } from 'react-toastify'
import { css, Global } from '@emotion/react'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'

const fontStyles = {
  fontFamily:
    'Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace',
  fontSize: 11,
}

function Layout({
  children,
  location,
  pageContext,
}: PageProps<any, { layout?: 'admin' }>) {
  let child = null as React.ReactNode

  if (pageContext.layout === 'admin') {
    child = children
  } else {
    child = (
      <>
        <Navbar location={location} />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            min-height: 100vh;
          `}
        >
          <div
            css={css`
              position: relative;
              flex-grow: 1;
            `}
          >
            {children}
          </div>
          <Footer location={location} />
        </div>
      </>
    )
  }

  return (
    <>
      <Global
        styles={{
          a: css`
            text-decoration: none !important;
            color: rgba(0, 0, 0, 0.75) !important;
            :active {
              color: rgb(55, 90, 118) !important;
            }
            :hover {
              transition: opacity 0.1s ease-out !important;
              opacity: 0.7 !important;
            }
          `,
          body: css`
            background: #fff;
            margin: 0px;
            padding: 0px;
            font-smooth: auto;
          `,
          pre: fontStyles,
        }}
      />
      {child}
      <ToastContainer autoClose={5000} />
    </>
  )
}

export default Layout
