import React from 'react'
import { css } from '@emotion/react'
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby'
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'

export interface LinkProps extends GatsbyLinkProps<HTMLElement> {
  disabled?: boolean
  muiLinkProps?: MuiLinkProps
}

function Link({
  children,
  className,
  disabled,
  to,
  muiLinkProps,
  ...rest
}: LinkProps) {
  let Component: React.ElementType<any>
  const props: any = {}
  const isLocal = !/^(http)/i.test(to)

  if (isLocal) {
    Component = React.forwardRef((props, ref) => (
      <GatsbyLink ref={ref} to={to} {...props} />
    ))
    props.css = css`
      opacity: ${disabled ? 0.6 : 1};
      cursor: ${disabled ? 'auto' : 'pointer'};
      pointer-events: ${disabled ? 'none' : 'auto'};
    `
  } else {
    Component = 'a'
    props.href = to
    props.target = '_blank'
    props.css = css`
      color: rgba(0, 0, 0, 0.75);
      font-size: inherit;
      :hover {
        color: #000;
      }
    `
  }

  return (
    <MuiLink
      component={Component}
      className={className}
      {...muiLinkProps}
      {...props}
      {...rest}
    >
      {children}
    </MuiLink>
  )
}

export default Link
