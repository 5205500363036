exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-react-cheatsheet-tsx": () => import("./../../../src/pages/react-cheatsheet.tsx" /* webpackChunkName: "component---src-pages-react-cheatsheet-tsx" */),
  "component---src-pages-sandpack-tsx": () => import("./../../../src/pages/sandpack.tsx" /* webpackChunkName: "component---src-pages-sandpack-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-posts-tsx": () => import("./../../../src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

