import { useStaticQuery, graphql } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

function useLogo() {
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/logo/blackWhite.png" }) {
        childImageSharp {
          gatsbyImageData(width: 170, height: 40, quality: 10)
        }
      }
    }
  `)

  return query.file?.childImageSharp?.gatsbyImageData as IGatsbyImageData
}

export default useLogo
