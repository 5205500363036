import createTheme from '@mui/material/styles/createTheme'
import deepOrange from '@mui/material/colors/deepOrange'
import lightBlue from '@mui/material/colors/lightBlue'
import green from '@mui/material/colors/green'
import red from '@mui/material/colors/red'

export const primary = {
  lighter: '#71c7ec',
  light: '#1ebbd7',
  main: '#189ad3',
  dark: '#107dac',
  darker: '#005073',
  // dark: '#2e475a',
  contrastText: '#fff',
}
// export const primary = {
//   light: '#2EBEAC',
//   main: '#29A19C',
//   dark: '#1E6C69',
//   // dark: '#2e475a',
//   contrastText: '#fff',
// }

export const secondary = {
  light: '#72FFA1',
  main: '#A3F7BF',
  dark: '#518875',
  contrastText: '#fff',
}

export const background = {
  dark: '#222731',
  // darker: '#242b2c',
  // dark: '#243035',
  light: '#eef5f9',
}

export const success = {
  light: green.A400,
  main: green.A700,
  dark: green[800],
}

export const error = {
  light: red.A100,
  main: red.A200,
  dark: red.A700,
}

export const info = {
  light: lightBlue.A100,
  main: lightBlue.A200,
  dark: lightBlue.A700,
}

export const warning = {
  light: deepOrange.A100,
  main: deepOrange.A200,
  dark: deepOrange.A700,
}

export const link = {
  main: '#2871a7',
  hovering: '#40a1cf',
}

export const social = {
  amazon: '#ff9900',
  deviantart: '#05cc47',
  facebook: '#3b5998',
  googleplus: '#db4437',
  instagram: '#8a3ab9',
  linkedin: '#007bb5',
  medium: '#02b875',
  pinterest: '#bd081c',
  quora: '#aa2200',
  reddit: '#ff4500',
  skype: '#00aff0',
  snapchat: '#fffc00',
  spotify: '#1ed760',
  tumblr: '#35465d',
  twitter: '#1da1f2',
  vimeo: '#1ab7ea',
  vk: '#4a76a8',
  yahoo: '#430297',
  youtube: '#ff0000',
}

export const sizes = {
  widescreen: 1600,
  desktop: 992,
  tablet: 768,
  phone: 492,
  mini: 400,
}

export default createTheme({
  palette: {
    primary,
    secondary,
    background,
    link,
    social,
    success,
    error,
    info,
    warning,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 468,
      md: 768,
      lg: 968,
      xl: 1280,
    },
  },
  typography: {
    fontSize: 11,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      '"Open Sans"',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h2: {},
    h4: {},
    h5: {},
    h6: {},
    subtitle1: {
      fontWeight: 300,
    },
    subtitle2: {},
  },
  // props: {
  //   MuiCssBaseline: {
  //     classes: {
  //       '@global': {
  //         html: {
  //           fontFamily: 'Roboto, sans-serif',
  //           fontSmoothing: 'auto',
  //           WebkitFontSmoothing: 'auto',
  //         },
  //       },
  //       '@font-face': [
  //         {
  //           fontFamily: 'Roboto, sans-serif',
  //           fontWeight: 400,
  //           fontStyle: 'normal',
  //         },
  //       ],
  //     },
  //   },
  //   MuiButton: {},
  //   MuiLink: {
  //     style: {
  //       color: 'rgba(0, 0, 0, 0.75)',
  //     },
  //   },
  //   MuiTableCell: {
  //     style: {
  //       textOverflow: 'ellipsis',
  //       overflowX: 'hidden',
  //       whiteSpace: 'nowrap',
  //       // '&:hover': {
  //       //   textOverflow: 'initial',
  //       //   overflowX: 'visible',
  //       // },
  //     },
  //   },
  //   MuiTypography: {
  //     style: {
  //       color: 'rgba(0, 0, 0, 0.75)',
  //     },
  //   },
  // },
})
