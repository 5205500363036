import React from 'react'
import { css } from '@emotion/react'
import { styled } from '@mui/material/styles'
import useTheme from '@mui/material/styles/useTheme'
import Typography, {
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography'
import CodeBlock from './CodeBlock'

interface TypographyProps extends Partial<Omit<MuiTypographyProps, 'ref'>> {}

export const Blockquote = styled('blockquote')(({ theme }) => ({
  borderLeft: `4px solid ${theme.palette.primary.main}`,
  [theme.breakpoints.down('xs')]: {
    margin: '2px 12px 6px 0',
  },
  '& p': {
    fontStyle: 'italic',
    marginLeft: 10,
  },
}))

export const Code = CodeBlock

export const InlineCode = styled('span')(({ theme }) => ({
  borderRadius: 3,
  background: `rgba(150, 10, 250, 0.09)`,
  color: `#333`,
  fontFamily: `Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace`,
  fontSize: '0.7rem',
  paddingLeft: 3,
  paddingRight: 3,
}))

export function H1({ style, ...rest }: TypographyProps) {
  return (
    <Typography
      variant="h1"
      style={{ ...style, fontSize: 50, fontWeight: 400 }}
      {...rest}
    />
  )
}

export function H2({ style, ...rest }: TypographyProps) {
  return (
    <Typography
      variant="h2"
      style={{ ...style, fontSize: 40, fontWeight: 300 }}
      {...rest}
    />
  )
}

export function H3({ style, ...rest }: TypographyProps) {
  return (
    <Typography
      variant="h3"
      style={{ ...style, fontSize: 35, fontWeight: 400 }}
      {...rest}
    />
  )
}

export function H4({ style, ...rest }: TypographyProps) {
  return (
    <Typography
      variant="h4"
      style={{ ...style, fontSize: 30, fontWeight: 300 }}
      {...rest}
    />
  )
}

export function H5({ style, ...rest }: TypographyProps) {
  return (
    <Typography
      variant="h5"
      style={{ ...style, fontSize: 25, fontWeight: 300 }}
      {...rest}
    />
  )
}

export function H6({ style, ...rest }: TypographyProps) {
  return (
    <Typography
      variant="h6"
      style={{ ...style, fontSize: 20, fontWeight: 300 }}
      {...rest}
    />
  )
}

export const Img = (
  props: React.PropsWithChildren<React.HTMLAttributes<HTMLImageElement>>,
) => {
  const theme = useTheme()
  return (
    <img
      css={css`
        max-width: 100%;
        transition: all 0.2s ease-out;
        display: block;
        margin: 25px auto 45px;
        border: 1px solid #e5ebed;
        object-fit: cover;
        :hover {
          transform: scale(1.02) rotate(-1deg);
        }
        ${theme.breakpoints.down('sm')} {
          width: 100%;
          height: 100%;
        }
        ${theme.breakpoints.down('xs')} {
          margin: 16px auto 30px;
        } ;
      `}
      {...props}
    />
  )
}

export const Ul = styled('ul')(({ theme }) => ({
  fontWeight: 300,
}))

export const Li = styled('li')(({ theme }) => ({
  fontWeight: 300,
  [theme.breakpoints.down('xs')]: {
    fontSize: 11,
  },
}))

export function Pre(props: React.HTMLProps<HTMLPreElement>) {
  return <pre {...props} />
}

export function P({ style, ...rest }: TypographyProps) {
  return (
    <p
      style={{
        ...style,
        fontSize: 14,
        fontWeight: 300,
      }}
      {...rest}
    />
  )
}

export function Span({ style, ...rest }: React.HTMLProps<HTMLSpanElement>) {
  return <span style={{ ...style, lineHeight: 1.6 }} {...rest} />
}
