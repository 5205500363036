import React from 'react'
import { css } from '@emotion/react'
import useTheme from '@mui/material/styles/useTheme'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Link from 'components/common/Link'
import Icon, { IconProps } from 'components/common/Icon'
import type { RouteObject } from 'app/config'

export interface BurgerMenuProps {
  routes: RouteObject[]
  selectedRoute: string
}

function BurgerMenu({ routes, selectedRoute }: BurgerMenuProps) {
  const [opened, setOpened] = React.useState(false)
  const open = () => setOpened(true)
  const close = () => setOpened(false)
  const theme = useTheme()

  return (
    <div
      css={css`
        display: none;
        ${theme.breakpoints.down('xs')} {
          display: block;
        }
      `}
    >
      <IconButton
        title="Open menu"
        onClick={opened ? close : open}
        size="small"
      >
        <Icon name="menu" />
      </IconButton>
      <Drawer variant="temporary" anchor="left" open={opened} onClose={close}>
        <List style={{ minWidth: 200 }}>
          {routes.map(({ label, to = '', disabled, icon }) => (
            <Link
              key={to}
              to={to}
              css={css`
                color: rgba(0, 0, 0, 0.8);
                :hover {
                  color: #000;
                }
              `}
            >
              <ListItem
                style={{ visibility: opened ? 'visible' : 'hidden' }}
                disabled={disabled}
                onClick={close}
                selected={selectedRoute === to}
                button
              >
                {Icon && <Icon name={icon as IconProps['name']} />}
                <span style={{ width: 15 }} />
                <ListItemText>{label}</ListItemText>
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </div>
  )
}

export default BurgerMenu
