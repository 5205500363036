import wrapWithProvider from './wrapWithProvider.js'
import '@codesandbox/sandpack-react/dist/index.css'
import '@fontsource/open-sans/300.css'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/500.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import 'react-toastify/dist/ReactToastify.css'
import 'prismjs/themes/prism-tomorrow.css'

export const wrapRootElement = wrapWithProvider
