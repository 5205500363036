import React from 'react'
import { css, ClassNames } from '@emotion/react'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'
import green from '@mui/material/colors/green'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import FilledInput from '@mui/material/FilledInput'
import Icon from 'components/common/Icon'
import renderError from 'utils/renderError'

export interface SubscribeNewsletterProps {
  onSubmit(e: React.FormEvent<HTMLFormElement>, email: string): Promise<void>
  minWidth?: string | number
  maxWidth?: string | number
  submitting?: boolean
  submitted?: boolean
  error?: null | Error
}

function SubscribeNewsletter({
  onSubmit: onSubmitProp,
  submitting,
  submitted,
  error,
  minWidth,
  maxWidth,
  ...props
}: React.PropsWithChildren<SubscribeNewsletterProps>) {
  const [value, setValue] = React.useState('')
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down('md'))

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    const target: typeof e.target & {
      email?: { value: string }
    } = e.target
    return onSubmitProp(e, target.email?.value || '')
  }

  const buttonText = (
    <Typography style={{ color: submitted ? green.A400 : '#eee' }}>
      {submitting ? 'Submitting...' : submitted ? 'Thank you!' : 'Subscribe!'}
    </Typography>
  )

  return (
    <Paper
      css={css`
        background: linear-gradient(
          0.3turn,
          #2e485a,
          #2e485a,
          #2e485a,
          #2e485a,
          90%,
          #4e3d58
        );
        color: #fff !important;
        padding: 30px 20px;
        border-right: 1px solid rgba(102, 134, 194, 0.75);
        border-bottom: 6px solid #fff;
        min-width: ${minWidth}px;
        max-width: ${maxWidth}px;
        ${theme.breakpoints.down('sm')} {
          min-width: 100% !important;
          border-bottom: 0px;
        } ;
      `}
      component="form"
      onSubmit={onSubmit as any}
      square={isMd}
    >
      <Typography
        variant={isMd ? 'h6' : 'h5'}
        align="center"
        style={{ color: '#fff' }}
      >
        Subscribe to the Newsletter
      </Typography>
      <Typography
        variant={isMd ? 'body2' : 'h6'}
        align="center"
        style={{ color: '#fff' }}
      >
        Get continuous updates
      </Typography>
      <ClassNames>
        {({ css, cx }) => (
          <FormControl
            size={isMd ? 'small' : 'medium'}
            margin={isMd ? 'dense' : 'normal'}
            fullWidth
          >
            <FilledInput
              type="email"
              name="email"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="EMAIL ADDRESS"
              classes={{
                input: cx(css`
                  border-radius: 4px;
                  padding-top: 20px;
                  padding-bottom: 20px;
                  color: ${theme.palette.primary.main};
                  text-align: center;
                  background-color: #eee;
                  ::placeholder {
                    color: ${theme.palette.primary.dark};
                    text-align: center;
                  }
                  ${theme.breakpoints.down('sm')} {
                    margin: auto;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    max-width: 250px;
                    padding-left: 0px;
                    padding-right: 0px;
                  } ;
                `),
              }}
              required
              disableUnderline
            />
          </FormControl>
        )}
      </ClassNames>
      <Button
        css={css`
          ${error ? `color: ${theme.palette.error.main};` : ''}
          margin: 0px auto 6px;
          padding: 14px 12px;
          display: flex;
          text-align: center;
          background: rgba(28, 34, 41, 0.7);
          border-top: 4px solid ${theme.palette.secondary.main};
          border-bottom: 4px solid rgba(0, 0, 0, 0);
          width: 100%;
          :hover {
            background: #2a3339;
            border-top: 4px solid rgba(0, 0, 0, 0);
            border-bottom: 4px solid ${theme.palette.secondary.light};
            img {
              transform: scale(1.13);
            }
          }
          :active {
            img {
              transform: scale(1.2) !important;
            }
          }
          img {
            transition: all 0.1s ease-out;
          }
          ${theme.breakpoints.down('sm')} {
            padding: 7px 6px;
            width: 100%;
            max-width: 250px;
          }
        `}
        type="submit"
        size={isMd ? 'small' : 'large'}
        disabled={!!submitting}
        disableRipple
      >
        <Icon name="click" /> &nbsp;
        {buttonText}
      </Button>
      {error && !submitted && renderError(error)}
    </Paper>
  )
}

export default SubscribeNewsletter
