import type { IconProps } from 'components/common/Icon'
import type Icon from 'components/common/Icon'

export interface RouteObject {
  disabled?: boolean
  name?: IconProps['name']
  to?: string
  label?: string
  Icon?: typeof Icon
  icon?: IconProps['name']
}

export const routes: RouteObject[] = [
  { to: '/', label: 'Home', icon: 'home' },
  { to: '/posts', label: 'Posts', icon: 'list' },
  { to: '/about', label: 'About', icon: 'article.blog' },
  { to: '/contact', label: 'Contact', icon: 'contact' },
]

export const getResponse = {
  tags: {
    about: {
      tagId: 'eMwk',
      name: 'about',
      href: 'https://api.getresponse.com/v3/tags/eMwk',
      color: '',
    },
    homepage: {
      tagId: 'eM3T',
      name: 'homepage',
      href: 'https://api.getresponse.com/v3/tags/eM3T',
      color: '',
    },
    mobile: {
      tagId: 'ejWz',
      name: 'mobile',
      color: '',
      createdAt: '2019-07-02T03:23:22+0000',
    },
    post: {
      tagId: 'eMt2',
      name: 'post',
      href: 'https://api.getresponse.com/v3/tags/eMt2',
      color: '',
    },
    sidebar: {
      tagId: 'ej8l',
      name: 'sidebar',
      color: '',
      createdAt: '2019-07-02T03:22:24+0000',
    },
  },
  tokens: {
    subscribe: '8UfDG', // campaignToken
  },
}

export const baseUrl = 'https://jsmanifest.com'
export const graphqlUrl =
  process.env.NODE_ENV === 'development'
    ? // ? 'http://127.0.0.1:4000/graphql'
      'https://jsmanifest-graphql.netlify.app/'
    : 'https://jsmanifest-graphql.netlify.app/'

export const socialProfiles = {
  devto: 'https://dev.to/jsmanifest',
  disqus: 'https://disqus.com/by/jsmanifest/',
  github: 'https://github.com/jsmanifest',
  linkedIn: 'https://www.linkedin.com/company/jsmanifest',
  medium: 'https://medium.com/@jsmanifest',
  twitter: 'https://twitter.com/jsmanifest',
  tumblr: 'http://jsmanifest.tumblr.com/',
  'about.me': 'https://about.me/jsmanifest/',
  rss: 'https://jsmanifest.com/rss.xml',
}

export const emails = {
  contact: 'contact@jsmanifest.com',
  socialMedia: 'socialmedia@jsmanifest.com',
}
