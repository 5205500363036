import React, { HTMLAttributes } from 'react'
import add from 'resources/svg/add.svg'
import articlePost from 'resources/svg/article1.svg'
import articleList from 'resources/svg/article2.svg'
import articleBlog from 'resources/svg/article3.svg'
import code from 'resources/svg/coding.svg'
import collection from 'resources/svg/collection1.svg'
import contact from 'resources/svg/contact1.svg'
import click from 'resources/svg/click.svg'
import doubleClick from 'resources/svg/double-click.svg'
import deleteIcon from 'resources/svg/delete.svg'
import devto from 'resources/svg/dev.to.svg'
import edit from 'resources/svg/edit.svg'
import expand from 'resources/svg/expand.svg'
import extraContent from 'resources/svg/extra-content.svg'
import facebook from 'resources/images/facebook.png'
import fullscreen1 from 'resources/svg/fullscreen.svg'
import fullscreen2 from 'resources/svg/fullscreen2.svg'
import gist from 'resources/images/gist.png'
import github from 'resources/svg/github-blue-purple.svg'
import home from 'resources/svg/home1.svg'
import linkedin from 'resources/images/linkedin.png'
import listDefault from 'resources/svg/list1.svg'
import listClipboard from 'resources/svg/list2.svg'
import listOverview from 'resources/svg/list3.svg'
import listPaper from 'resources/svg/list4.svg'
import listReading from 'resources/svg/list5.svg'
import logout from 'resources/svg/logout.svg'
import medium from 'resources/svg/medium-navy.svg'
import menu from 'resources/svg/menu1.svg'
import notes from 'resources/svg/notes.svg'
import nsfw from 'resources/svg/nsfw.svg'
import resources from 'resources/svg/resources.svg'
import reddit from 'resources/images/reddit.png'
import rightArrow from 'resources/images/right-arrow.png'
import rss from 'resources/svg/rss-navy.svg'
import settings from 'resources/svg/settings.svg'
import twitter from 'resources/svg/twitter-blue-purple-squared.svg'
import wave from 'resources/svg/wave.svg'
import { CustomIconProps } from 'app/types'

export interface IconProps
  extends CustomIconProps,
    Omit<HTMLAttributes<HTMLImageElement>, 'color' | 'name'> {
  name:
    | 'add'
    | 'article.post'
    | 'article.list'
    | 'article.blog'
    | 'code'
    | 'collection'
    | 'contact'
    | 'click'
    | 'double.click'
    | 'delete'
    | 'devto'
    | 'edit'
    | 'expand'
    | 'extra-content'
    | 'facebook'
    | 'fullscreen1'
    | 'fullscreen2'
    | 'gist'
    | 'github'
    | 'home'
    | 'linkedin'
    | 'list'
    | 'list.clipboard'
    | 'list.overview'
    | 'list.paper'
    | 'list.reading'
    | 'logout'
    | 'menu'
    | 'medium'
    | 'notes'
    | 'nsfw'
    | 'reddit'
    | 'resources'
    | 'right.arrow'
    | 'rss'
    | 'settings'
    | 'twitter'
    | 'wave'
}

function getIcon(name: string) {
  switch (name) {
    case 'add':
      return add
    case 'article.blog':
      return articleBlog
    case 'article.list':
      return articleList
    case 'article.post':
      return articlePost
    case 'code':
      return code
    case 'contact':
      return contact
    case 'collection':
      return collection
    case 'click':
      return click
    case 'double.click':
      return doubleClick
    case 'delete':
      return deleteIcon
    case 'devto':
      return devto
    case 'edit':
      return edit
    case 'expand':
      return expand
    case 'extra.content':
      return extraContent
    case 'facebook':
      return facebook
    case 'fullscreen1':
      return fullscreen1
    case 'fullscreen2':
      return fullscreen2
    case 'gist':
      return gist
    case 'github':
      return github
    case 'home':
      return home
    case 'linkedin':
      return linkedin
    case 'list':
      return listDefault
    case 'list.clipboard':
      return listClipboard
    case 'list.default':
      return listDefault
    case 'list.overview':
      return listOverview
    case 'list.paper':
      return listPaper
    case 'list.reading':
      return listReading
    case 'logout':
      return logout
    case 'medium':
      return medium
    case 'menu':
      return menu
    case 'notes':
      return notes
    case 'nsfw':
      return nsfw
    case 'reddit':
      return reddit
    case 'resources':
      return resources
    case 'right.arrow':
      return rightArrow
    case 'rss':
      return rss
    case 'settings':
      return settings
    case 'twitter':
      return twitter
    case 'wave':
      return wave
    default:
      return null
  }
}

function renderImg(src: string) {
  return <img src={src} width={35} height={35} alt="" />
}

function Icon({
  color,
  name,
  width = 35,
  height = 35,
  ...rest
}: React.PropsWithChildren<IconProps>) {
  const src = getIcon(name)
  return src ? <img src={src} width={width} height={height} {...rest} /> : null
}

export default Icon
