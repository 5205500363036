import React from 'react'
import { ApolloError, ServerError } from '@apollo/client'
import Typography from '@mui/material/Typography'

function renderAsString(errorMessage: string) {
  return (
    <Typography
      variant="body2"
      color="error"
      style={{ paddingTop: 10, paddingBottom: 4 }}
    >
      {errorMessage}
    </Typography>
  )
}

function renderAsListItems(errors: Error[]) {
  return (
    <>
      {errors.map((err) => (
        <li key={err.message}>{err.message}</li>
      ))}
    </>
  )
}

/**
 * Supports parsing and rendering the ApolloError as well as the native Error
 * @param { ApolloError | Error | null } error
 */
function renderError(error: ApolloError | Error | null) {
  let children

  if (error) {
    if (error instanceof ApolloError) {
      if (error.networkError || error.graphQLErrors) {
        let errorMsgs

        if (error.networkError) {
          const { networkError } = error
          if ((networkError as ServerError)?.result?.errors) {
            const { errors } = (error.networkError as ServerError).result
            errorMsgs = renderAsListItems(errors)
          } else if (networkError.message) {
            errorMsgs = renderAsString(networkError.message)
          }
        } else if (error.graphQLErrors) {
          errorMsgs = renderAsListItems(error.graphQLErrors as any)
        }

        if (!errorMsgs) {
          errorMsgs = renderAsString(error.message)
        }

        children = (
          <Typography
            component="ul"
            variant="body2"
            style={{ paddingTop: 10, paddingBottom: 4 }}
            color="error"
          >
            {errorMsgs}
          </Typography>
        )
      } else {
        children = renderAsString(error.message)
      }
    } else {
      children = renderAsString(error.message)
    }
  }

  return children ? <>{children}</> : null
}

export default renderError
