import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { Helmet } from 'react-helmet'
import { MDXProvider } from '@mdx-js/react'
import { ThemeProvider as MuiThemeProvider } from '@mui/styles'
import { RecoilRoot } from 'recoil'
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  HttpLink,
} from '@apollo/client'
import fetch from 'isomorphic-fetch'
import theme from './src/app/theme'
import { graphqlUrl } from './src/app/config'
import CodeBlock, {
  Blockquote,
  Code,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Img,
  InlineCode,
  Ul,
  Li,
  P,
  Pre,
  Span,
} from './src/components/CodeBlock'
import favicon from './src/resources/favicon.png'

const client = new ApolloClient({
  connectToDevTools: true,
  link: new HttpLink({
    uri: graphqlUrl,
    fetch,
  }),
  cache: new InMemoryCache(),
})

const shortcodes = {
  blockquote: Blockquote,
  code: Code,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  img: Img,
  inlineCode: InlineCode,
  ul: Ul,
  li: Li,
  p: P,
  pre: Pre,
  span: Span,
  CodeBlock,
}

export default ({ element, headComponents }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge; charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {headComponents}
        <meta
          name="google-site-verification"
          content="TIdCUhpeuP8fVXjI4ucBlU0GXFwapoCQb0cKCTpyjyk"
        />
        <meta
          name="google-site-verification"
          content="TIdCUhpeuP8fVXjI4ucBlU0GXFwapoCQb0cKCTpyjyk"
        />
        <script
          type="text/javascript"
          src="https://platform.linkedin.com/badges/js/profile.js"
          async
          defer
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
      </Helmet>
      <MDXProvider components={shortcodes}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <RecoilRoot>
            <ApolloProvider client={client}>{element}</ApolloProvider>
          </RecoilRoot>
        </MuiThemeProvider>
      </MDXProvider>
    </>
  )
}
