import React from 'react'
import { css } from '@emotion/react'
import { RouteComponentProps } from '@reach/router'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import Link from 'components/common/Link'
import SubscribeNewsletter from 'components/SubscribeNewsletter'
import Icon, { IconProps } from 'components/common/Icon'
import useSubscribeToNewsletter from 'hooks/useSubscribeToNewsletter'
import { socialProfiles } from 'app/config'

function Footer({ location }: Pick<RouteComponentProps, 'location'>) {
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isMd = useMediaQuery(theme.breakpoints.down('md'))
  const isWide = useMediaQuery(theme.breakpoints.up('md'))

  const { subscribe, loading, called, data, error } = useSubscribeToNewsletter()

  async function onSubscribeNewsletter(e: React.SyntheticEvent, email: string) {
    e.preventDefault()
    await subscribe({
      variables: {
        email,
        pageUrl: location?.href,
        type: 'mobile',
      },
    })
  }

  function FloatingColumns({ left }: { left?: React.ReactNode }) {
    return (
      <>
        <Grid xs={12} md={5} item>
          {left}
        </Grid>
        <Grid sm={7} item />
      </>
    )
  }

  const socialMediaItems: {
    title: string
    name: IconProps['name']
    url: string
  }[] = [
    { title: 'Medium', name: 'medium', url: socialProfiles.medium },
    { title: 'dev.to', name: 'devto', url: socialProfiles.devto },
    { title: 'Twitter', name: 'twitter', url: socialProfiles.twitter },
    { title: 'GitHub', name: 'github', url: socialProfiles.github },
    { title: 'rss', name: 'rss', url: socialProfiles.rss },
  ]

  return (
    <>
      <div
        css={css`
          height: 80px;
          ${theme.breakpoints.down('sm')} {
            height: 40px;
          }
        `}
      />
      <footer
        css={css`
          position: relative;
          display: flex;
          flex-direction: column;
        `}
      >
        <Grid
          css={css`
            background: ${theme.palette.background.dark} !important;
            flex-basis: 80;
            position: absolute;
            top: 0px;
            bottom: 0px;
            height: ${isWide ? '380px' : !isSm && isMd ? '450px' : '380px'};
            ${theme.breakpoints.down('sm')} {
              padding-left: 0px;
              padding-right: 0px;
            }
          `}
          alignItems="center"
          container
        >
          <Container maxWidth="md">
            <FloatingColumns
              left={
                <Box display="center" justifyContent="center">
                  <SubscribeNewsletter
                    onSubmit={onSubscribeNewsletter}
                    submitting={loading}
                    submitted={!!data}
                    error={error}
                    minWidth={350}
                    maxWidth={350}
                  />
                </Box>
              }
            />
          </Container>
        </Grid>
        <div
          css={css`
            flex-grow: 1;
          `}
        >
          <Container
            css={css`
              height: 100%;
              ${isSm} {
              }
            `}
            maxWidth="md"
          >
            <Grid
              css={css`
                height: 100%;
              `}
              justifyContent={isWide ? 'flex-end' : 'center'}
              alignItems="flex-end"
              container
            >
              <Grid xs={12} md={6} item />
              <Grid
                css={css`
                  position: absolute;
                  z-index: 1;
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                  img {
                    margin: 4;
                    :hover {
                      opacity: 0.8;
                    }
                  }
                `}
                xs={12}
                md={6}
                item
              >
                {socialMediaItems.map(({ title, name, url }) => (
                  <Link key={name} to={url}>
                    <Icon title={title} name={name} />
                  </Link>
                ))}
              </Grid>
              <Grid xs={12} style={{ height: 20 }} item />
              <Grid xs={12} item>
                <Typography
                  component="div"
                  align="center"
                  css={css`
                    color: #eee !important;
                    padding: 25px;
                    ${theme.breakpoints.down('sm')} {
                      padding: 15px;
                    } ;
                  `}
                  variant="caption"
                >
                  &copy; jsmanifest {new Date().getFullYear()}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </div>
      </footer>
    </>
  )
}

export default Footer
